<template>
  <div class="calendar">
    <div class="calendar-header">{{ currentMonth }}月</div>
    <div class="calendar-body">
      <div v-for="day in daysInMonth" :key="day" class="day">
        {{ day }}
      </div>
    </div>
    <div class="music-player">
    <audio controls>
        <source src="path_to_your_audio_file.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
  </div>
  </div>

</template>

<script setup>
import { ref, computed } from 'vue';

const currentMonth = ref(new Date().getMonth() + 1);
const daysInMonth = computed(() => new Date(new Date().getFullYear(), currentMonth.value, 0).getDate());
</script>

<style lang="scss" scoped>
.calendar {
  width: 300px;
  height: 300px;
  position: fixed;
  right: 3px;
  margin-top: 0%;
  font-size: 16px;
}
.calendar-header {
  height: 10%;
  font-size: 24px;
  color: rgb(245, 239, 239);
  background-color: rgb(44, 42, 42);
  font-display: inherit;
  font-weight: lighter;
}
.calendar-body {
  height: 90%;
  background-color: aqua;
  display: flex;
  flex-wrap: wrap;
}
.day {
  width: 14.28%; // 100 / 7 days
  height: 14.28%; // 100 / 7 days
  border: 1px solid #000;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.music-player {
    margin-top: 20px;
    text-align: center;
  }
</style>