<template>
  <div class="home">
    <el-container>
      <el-header  > <NavNar></NavNar></el-header>
      <el-main>
      <div clss="loading-container">
        <el-el-loading text="Loading" fullscreen></el-el-loading>
      </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import NavNar from "@/components/navigation/NavigationBar";
import HomeSide from "@/components/sides/HomeSide";
/* eslint-disable */
export default {
  components: {
    NavNar,
    HomeSide,
  },
  data() {
    return {
      key: "value",
    };
  },
  mounted() {},
  methods: {
    init() {},
  },
};
</script>

<style scoped>
.home{
  width: 100%;
  height: 100%;
}
.home p {
  text-align: center; /* 添加这一行 */
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 添加这一行 */
  font-size: 24px;
  margin-top: 100px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.el-header {
  --el-header-padding: 0px;
}
.el-container{
  display: flex;
}
.el-main{
  --el-main-padding: 0px;
}
</style>
