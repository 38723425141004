<template>
  <div class="nav-bar">
    <h3>Emoc</h3>
    <img  src="@/assets/pngs//dl.png" alt="girl god"  class="nav-image" />
    <ul>
      <li><a href="/">首页</a></li>
      <li><a href="/about">博客</a></li>
      <li><a href="/contact">相册</a></li>
      <li><a href="/contact">杂记</a></li>
      <li><a href="/news">动态</a></li>
      <li><a href="/contact">联系我</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.nav-bar {
  background-color: rgb(243, 249, 253); /* 修改这一行 */
  height: 60px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6; /* 添加这一行 */
  align-items: center; /* 添加这一行 */
}

.nav-bar h3 {
  line-height: 50px;
  font-family: "Cursive";
  font-size: 40px;
  font-weight: bold;
  padding-left: 30px;
  margin-left: 5%;
}

.nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.nav-bar li {
  float: left;
  /* margin-left: 10px; */
}

.nav-bar li a {
  display: block;
  color: #333; /* 修改这一行 */
  text-align: center;
  padding: 14px 30px; /* 修改这一行 */
  text-decoration: none;
  font-size: 18px; /* 添加这一行 */
}

.nav-bar li a:hover {
  color: #007fff; /* 添加这一行 */
}

.nav-image { /* 添加这个样式 */
  height: 100%;
  width: auto;
  padding-left: 180px;
}

</style>
